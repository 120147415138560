/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    blockquote: "blockquote",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("table", {
    style: {
      width: '100%'
    }
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Diseño"), "\n", React.createElement("th", null, "Camiseta"), "\n", React.createElement("th", null, "Sudadera"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "Entre 55€ y 100€"), "\n", React.createElement("td", null, "Por cada una 16€"), "\n", React.createElement("td", null, "Por cada una 36€")))), "\n", React.createElement(_components.h3, null, "¿Quieres algún dibujo?"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Puedes ver más y contactar conmigo en ", React.createElement(_components.a, {
    href: "https://www.instagram.com/lady_toxiclake/"
  }, "Instagram")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
